.wrapDown {
    display: flex;
    align-content: flex-end;
    flex-wrap: wrap;
}

.min-vh100-around {
    min-height: 100vh;
    display: flex !important;
    align-content: space-around;
    flex-flow: wrap;
}

.w100 {
    width: 100%;
}