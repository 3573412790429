header{
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 0px;
}
.header{
    
    background-color: white;
    
}