.block404 {
    padding: 80px 0px;
    display: block;
    max-width: 500px;
    width: 80%;
    margin: auto;
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}

.block404 h2 {
    margin: 0;
    font-size: 100px;
}

.block404 h3 {
    margin-bottom: 30px;
    width: 100%;
}

.block404 p {
    line-height: 20px;
}

.MuiButtonBase-root.btnGoHome {
    text-transform: inherit;
    font-weight: 600;
    font-size: 16px;
    display: block;
    margin: auto;
    margin-top: 40px;
}

.btnGoHomeLink {
    color: inherit;
    text-decoration: none;
}