.logo {
    max-width: 75px;
    object-fit: contain;
}

.min-vh100-center {
    min-height: 100vh;
    display: flex;
    align-content: center;
    flex-flow: wrap;
}

.AboutH4 {
    font-family: 'Graphic-web', Helvetica, sans-serif;
    margin-top: 30px;
    font-size: 40px;
    font-weight: 600;
    color: #242424;
}

.AboutTitle .svg--hand {
    width: 29px;
    height: 29px;
    margin-top: -55px;
    margin-left: 113px;
    display: inline-flex;
    position: absolute;
}

.textCenter {
    text-align: center;
}

.AboutTitle .svg--hand svg {
    width: 100%;
    height: 100%;
    overflow: visible;
    fill: #1f1f1f;
}

.AboutTitle #hand {
    transition-delay: 0.5s;
    transform: rotate(0deg);
    transform-origin: 10% 90%;
    animation: hand-frames 1s 0.3s linear forwards;
}

@keyframes hand-frames {
    0% {
        transform: rotate(0deg);
        transform-origin: 10% 90%;
    }

    25% {
        transform: rotate(11deg);
        transform-origin: 10% 90%;
    }

    50% {
        transform: rotate(-10deg);
        transform-origin: 10% 90%;
    }

    75% {
        transform: rotate(11deg);
        transform-origin: 10% 90%;
    }

    100% {
        transform: rotate(0deg);
        transform-origin: 10% 90%;
    }
}

.boxPapper img,
.boxPapper p {
    pointer-events: none;
}

.boxPapper {
    padding: 20px;
    cursor: pointer;
    text-align: center
}

.boxAvatar {
    width: 150px;
    height: 150px;
    margin: auto;
    cursor: pointer;
}

.bgAvatar {
    position: absolute;
    margin-top: -18px;
    margin-left: -20px;
    fill: whitesmoke
}

.bgAvatar {
    transition: all 0.3s;
    pointer-events: none;
}

.active .bgAvatar {
    fill: #14a800;
    transform: scale(1.2);
}

.boxPapper:hover .bgAvatar {
    fill: whitesmoke;
    transform: scale(1.2);
}

.boxPapper:active .bgAvatar {
    fill: whitesmoke;
    transform: scale(1);
}

.active:hover .bgAvatar {
    fill: #14a800;
    transform: scale(1.15);
}

.active:active .bgAvatar {
    fill: #14a800;
    transform: scale(1);
}

.titleAdd {
    font-weight: 600;
    font-size: 25px;
    max-width: 250px;
    padding: 50px 0 20px 0;
    margin: auto;
    text-align: center;
}

.modulSand .MuiDialog-paper {
    border-radius: 40px;
    padding: 10px;
    max-width: 550px;
}

.Mui-disabled {
    color: white !important;
    opacity: .5 !important;
}